import { makeSlug } from '/machinery/makeSlug'

import styles from './Toggle.css'

export const Toggle = React.forwardRef(ToggleImpl)

export function ToggleImpl({ name, value, options, onValueChange, layoutClassName = undefined }, ref) {
  const activeItemIndex = Math.max(0, options.findIndex(x => x.value === value))

  return (
    <div className={cx(styles.componentImpl, layoutClassName)} {...{ ref }}>
      <fieldset
        className={styles.inner}
        style={{
          '--option-count': options?.length,
          '--active-item-index': activeItemIndex
        }}
      >
        {options?.map(({ value, label }, i) => (
          <React.Fragment key={i}>
            <input
              id={value}
              type="radio"
              className={styles.isHidden}
              onChange={handleChange}
              data-x={`click-to-toggle-${makeSlug({ input: label, language: 'en' })}`}
              {...{ value, name }}
            />
            <label
              htmlFor={value}
              className={cx(styles.option, activeItemIndex === i && styles.isActive)}
            >
              {label}
            </label>
          </React.Fragment>
        ))}
      </fieldset>
    </div>
  )

  function handleChange(e) {
    onValueChange(e.currentTarget.value)
  }
}
