import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { JobsListAndFilter } from '/features/pageOnly/jobsOverview/JobsListAndFilter'

const client = new QueryClient()

export default function JobsListAndFilterApp({ lateNight, initialJobsAndFilters, title, isLoggedIn, country }) {
  return (
    <QueryClientProvider {...{ client }}>
      <JobsListAndFilter {...{ lateNight, initialJobsAndFilters, title, isLoggedIn, country }} />
    </QueryClientProvider>
  )
}
