import { useEvent } from './useEvent'

export function useInterval({ active, delay, interval, callback, cleanupCallback }) {
  const safeCallback = useEvent(callback)
  const safeCleanupCallback = useEvent(cleanupCallback)

  React.useEffect(
    () => {
      if (!active) return

      let intervalId
      const timeoutId = setTimeout(
        () => {
          safeCallback()
          intervalId = setInterval(() => { safeCallback() }, interval)
        },
        delay
      )

      return () => {
        clearInterval(intervalId)
        clearTimeout(timeoutId)
        safeCleanupCallback()
      }
    },
    [active, delay, interval, safeCallback, safeCleanupCallback]
  )
}
