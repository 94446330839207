import { ContainerXl } from '/features/buildingBlocks/Container'
import { Toggle } from '/features/buildingBlocks/Toggle'
import { useTranslate } from '/machinery/I18n'
import { Ticker } from './Ticker'
import { useOnChange } from '/machinery/useOnChange'
import { countrySettings } from '/i18n/countrySettings'

import styles from './FilterHeader.css'

export function FilterHeader({ textQuery, locationType, onFilterChange, locationTypeOptions, country }) {
  const { officeType } = countrySettings[country]
  const [isInteracting, setIsInteracting] = React.useState(false)
  const toggleRef = React.useRef(null)
  const inputRef = React.useRef(null)
  const { __ } = useTranslate()

  const roles = useGetRoles(officeType)

  return (
    <header
      data-x='filters'
      onClick={handleFocus}
      className={styles.component}
    >
      <ContainerXl>
        <div className={styles.wrapper}>
          <div className={styles.container} >
            <h1 className={styles.italicSubHeading}>
              {__`component-FilterHeader-what-are-you-looking-for`}
            </h1>

            <div className={styles.inputContainer}>
              <SearchTextInput
                {...{ inputRef }}
                value={textQuery}
                layoutClassName={styles.searchTextInputLayout}
                onChange={textQuery => onFilterChange({ textQuery })}
                onFocus={() => setIsInteracting(true)}
                onBlur={() => setIsInteracting(false)}
              />

              <Ticker
                items={roles}
                active={!textQuery && !isInteracting}
                layoutClassName={styles.tickerLayout}
              />
            </div>
          </div>

          <Toggle
            ref={toggleRef}
            name={__`filter-type-label`}
            value={locationType}
            onValueChange={locationType => onFilterChange({ locationType })}
            options={locationTypeOptions}
          />
        </div>
      </ContainerXl>
    </header>
  )

  function handleFocus(e) {
    const hasToggleAsChild = e.target.contains(toggleRef.current)
    if (hasToggleAsChild) inputRef.current.focus()
  }
}

function SearchTextInput({ inputRef, onChange, onFocus, onBlur, value: initialValue, layoutClassName }) {
  const [value, setValue] = React.useState(initialValue)
  const { __ } = useTranslate()

  useOnChange({ ifChanged: initialValue, callback: setValue })

  return (
    <input
      id='search'
      type='text'
      ref={inputRef}
      aria-label={__`label-search`}
      className={cx(styles.componentSearchTextInput, layoutClassName)}
      onChange={e => {
        const { value } = e.currentTarget
        setValue(value)
        onChange(value)
      }}
      {...{ value, onFocus, onBlur }}
    />
  )
}

function useGetRoles(officeType) {
  const { __ } = useTranslate()

  const roles = {
    'RetailOnly': [
      __`role-shop-manager`,
      __`role-assistant-shop-manager`,
      __`role-sales-advisor`
    ],
    'default': [
      __`role-shop-manager`,
      __`role-assistant-shop-manager`,
      __`role-sales-advisor`,
      __`role-retail-coordinator`,
      __`role-hr-coordinator`,
      __`role-marketing-specialist`,
    ]
  }

  return roles[officeType] ?? roles.default
}
