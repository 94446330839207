import { FiltersOpenCloseButtonWithDot } from './Filters'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '@kaliber/use-media-query'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './SelectedFilterBar.css'

import iconClose from '/images/icons/close.raw.svg'

export function SelectedFilterBar({ active, onToggleFiltersClick, tags, onRemoveTag, lateNight, layoutClassName }) {
  const hasFilters = Boolean(tags.length)
  const isDesktop = useMediaQuery(mediaStyles.viewportMd)
  const activeTags = tags.length > 0

  return (
    <div
      className={cx(styles.component, activeTags && styles.isActive, layoutClassName)}
      data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
    >
      {isDesktop && <FiltersOpenCloseButtonWithDot {...{ hasFilters, active, onToggleFiltersClick }} />}
      <LabelAndTags {...{ tags, activeTags, onRemoveTag, lateNight }} layoutClassName={styles.labelsAndTagsLayout} />
    </div>
  )
}

function LabelAndTags({ tags, onRemoveTag, lateNight, activeTags, layoutClassName }) {
  const { __ } = useTranslate()
  return (
    <div
      className={cx(styles.componentLabelAndTags, activeTags && styles.isActive, layoutClassName)}
      data-style-context={lateNight ? cssStyleContext.contextBlack : cssStyleContext.contextWhite}
    >
      {Boolean(tags.length) && <span className={cx(styles.label, styles.labelLayout)}>{__`your-filters`}</span>}
      <Tags layoutClassName={styles.tagsLayout} {...{ tags, onRemoveTag }} />
    </div>
  )
}

function Tags({ tags, onRemoveTag, layoutClassName }) {
  return (
    <div className={cx(styles.componentTags, layoutClassName)}>
      {tags.map((tag, i) =>
        <Tag key={i} onClick={() => onRemoveTag(tag)} {...{ tag }} />
      )}
    </div>
  )
}

function Tag({ tag, onClick }) {
  const label = tag.type === 'latAndLonQuery' ? shortenTag(tag.label) : tag.label

  return (
    <button data-x='remove-tag' type='button' className={styles.componentTag} {...{ onClick }}>
      {label}
      <Icon icon={iconClose} layoutClassName={styles.tagIconLayout} />
    </button>
  )

  function shortenTag(string) {
    return string.length > 30 ? string.substring(0, 30) + '...' : string
  }
}
