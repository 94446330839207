import { animated, config, useTransition } from 'react-spring'

import { useInterval } from '/machinery/useInterval'

import styles from './Ticker.css'

export function Ticker({ active, items, layoutClassName = undefined }) {
  const internalItems = useCycleThroughArray(items, { active, interval: 4000 })

  const transitions = useTransition(internalItems, {
    from: { opacity: 0, blur: 2, clipPath: 100 },
    enter: { opacity: 1, blur: 0, clipPath: 0 },
    leave: { opacity: 0, blur: 2, clipPath: 100 },
    config: { ...config.gentle, duration: 750 },
    update: { opacity: active ? 1 : 0 },
    exitBeforeEnter: true,
    immediate: !active
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {transitions(({ clipPath, blur, opacity }, item) =>
        (
          <animated.div
            className={styles.transitionsItem}
            style={{
              clipPath: clipPath.to(x => `inset(0 ${x}% -10px 0)`),
              filter: `blur(${blur.to(x => x)}px)`,
              opacity
            }}
          >
            {item}
          </animated.div>
        ))}
    </div>
  )
}

function useCycleThroughArray(a, { active, interval }) {
  const [internalItems, setInternalItems] = React.useState(() => a.slice(0, 1))

  useInterval({
    active,
    delay: interval / 2,
    interval,
    callback() {
      const [current] = internalItems
      const nextIndex = (a.indexOf(current) + 1) % a.length
      setInternalItems([a[nextIndex]])
    },
    cleanupCallback() {
      setInternalItems([])
    }
  })

  return internalItems
}
