import styles from './Pagination.css'

export function Pagination({ total, itemsPerPage, currentSize, label, page, layoutClassName = undefined }) {
  const maxPages = Math.ceil(total / itemsPerPage)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={cx(styles.progress, styles.progressLayout)}>
        {Array.from({ length: maxPages }, (_, i) => (
          <Dash
            key={i}
            active={page >= (i + 1)}
            layoutClassName={styles.pageIndicatorLayout}
          />
        ))}
      </div>

      <p className={styles.text}>
        {label}
      </p>
    </div>
  )
}

function Dash({ active, layoutClassName }) {
  return (
    <div className={cx(styles.componentDash, active && styles.isActive, layoutClassName)} />
  )
}
