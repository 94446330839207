import { offset, useFloating, autoUpdate, flip, size, useClick, useDismiss, useRole, useListNavigation, useInteractions, FloatingPortal } from '@floating-ui/react'
import { LoaderSmall } from '/features/buildingBlocks/Loader'
import { Icon } from '/features/buildingBlocks/Icon'
import { useTranslate } from '/machinery/I18n'

import iconCrosshair from '/images/icons/crosshair.raw.svg'
import iconClose from '/images/icons/close.raw.svg'

import styles from './TextFieldWithAutocomplete.css'

export function TextFieldWithAutocomplete({
  id,
  value,
  options = [],
  isLoading = false,
  browserSupportsGeoLocation,
  currentSelectedPlaceLabel,
  hasCustomLocation,
  onInputValueChange,
  onClearLocation,
  onGetLocation,
  onSubmit,
  onSelect,
  layoutClassName = undefined
}) {
  const {
    isOpen,
    activeIndex,
    selectedIndex,
    itemProps,
    floatingProps,
    referenceProps
  } = useFloatingAutocomplete({ value, options, onInputValueChange, onSubmit, onSelect })
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.component, layoutClassName)} {...{ id }}>
      <div
        className={cx(styles.textField, hasCustomLocation && styles.isNotEditable)}
        {...referenceProps}
      >
        {
          hasCustomLocation
            ? <span className={styles.textPlaceholder}>{currentSelectedPlaceLabel}</span>
            : (
              <input
                type='text'
                className={styles.textInput}
                placeholder={__`filter-location-placeholder`}
              />
            )
        }

        {isLoading && (
          <div className={styles.iconButton}>
            <LoaderSmall />
          </div>
        )}

        {!isLoading && !hasCustomLocation && browserSupportsGeoLocation && (
          <button
            data-x='click-to-get-location'
            onClick={onGetLocation}
            className={styles.iconButton}
          >
            <Icon icon={iconCrosshair} layoutClassName={styles.iconLayout} />
          </button>
        )}

        {!isLoading && hasCustomLocation && (
          <button
            data-x='click-to-clear-location'
            onClick={onClearLocation}
            className={styles.iconButton}
          >
            <Icon icon={iconClose} layoutClassName={styles.iconLayout} />
          </button>
        )}
      </div>

      {isOpen && (
        <FloatingPortal id='autocomplete'>
          <Container layoutClassName={styles.containerLayout} {...floatingProps}>
            {options.map(({ label }, index) => (
              <Option
                key={index}
                option={label}
                {...{ index, selectedIndex, activeIndex }}
                {...itemProps(index)}
              />
            ))}
          </Container>
        </FloatingPortal>
      )}
    </div>
  )
}

const Option = React.forwardRef(OptionImpl)
function OptionImpl({ option, index, selectedIndex, activeIndex, ...rest }, ref) {
  const isSelected = index === selectedIndex
  const isActive = index === activeIndex

  return (
    <button
      key={option}
      role="option"
      tabIndex={activeIndex === index ? 0 : -1}
      data-x='click-to-select-option'
      aria-selected={isSelected && isActive}
      className={cx(
        styles.componentOptionImpl,
        isActive && styles.isActive,
      )}
      {...{ ref }}
      {...rest}
    >
      {option}
    </button>
  )
}

const Container = React.forwardRef(ContainerImpl)
function ContainerImpl({ children, layoutClassName = undefined, ...rest }, ref) {
  return (
    <div className={cx(styles.componentContainerImpl, layoutClassName)} {...{ ref }} {...rest}>
      {children}
    </div>
  )
}

function useFloatingAutocomplete({
  value,
  options,
  onInputValueChange,
  onSubmit,
  onSelect
}) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(value)
  const [activeIndex, setActiveIndex] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    return options.findIndex(x => x.value === value) ?? 0
  })

  React.useEffect(
    () => { setIsOpen(Boolean(options?.length)) },
    [options]
  )

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    whileElementsMounted: autoUpdate,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 2, crossAxis: 1 }),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width - 2}px`,
            maxWidth: `${rects.reference.width - 2}px`
          })
        },
        padding: 10
      })
    ]
  })

  const listRef = React.useRef([])

  const click = useClick(context, { keyboardHandlers: false }) /* with default value keyboardHandlers = true, hitting space when you're in the text input is interpreted as trying to toggle the floating element, resulting in the space not being added */
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'listbox' })
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true
  })

  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([dismiss, role, listNav, click])

  return {
    isOpen,
    activeIndex,
    selectedIndex,
    referenceProps: {
      ref: refs.setReference,
      ...getReferenceProps({
        value: inputValue,
        onChange: handleInputValueChange
      })
    },
    floatingProps: {
      ref: refs.setFloating,
      style: floatingStyles,
      ...getFloatingProps()
    },
    itemProps: i => ({
      ref: node => {
        listRef.current[i] = node
      },
      ...getItemProps({
        onClick() {
          handleSelect(i)
        },
        onKeyDown(event) {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleSelect(i)
          }
        }
      })
    })
  }

  function handleInputValueChange(e) {
    const value = e.target.value
    setInputValue(value)
    onInputValueChange(value)
  }

  function handleSelect(x) {
    if (options?.[x]) {
      onSubmit(options[x])
      onSelect(x)
      setSelectedIndex(x)
    }
  }
}
