/**
 * @typedef {({lat, lon}) => void} Callback
 * @returns {{
 *  isLoading: boolean,
 *  browserSupportsGeoLocation: boolean,
 *  getCurrentPosition: (callback: Callback) => void
 * }}
 */
export function useGeoLocation() {
  const [isSupported, setIsSupported] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(
    () => { setIsSupported('geolocation' in navigator) },
    []
  )

  const getCurrentPosition = React.useCallback((callback) => {
    if (isSupported) {
      setIsLoading(true)

      navigator.geolocation.getCurrentPosition(o => {
        const { latitude: lat, longitude: lon } = o.coords
        callback({ lat, lon })
        setIsLoading(false)
      }, (error) => {
        console.error(error)
        setIsLoading(false)
      })
    }
  }, [isSupported])

  return {
    isLoading,
    browserSupportsGeoLocation: isSupported,
    getCurrentPosition,
  }
}
